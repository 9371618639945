import { useContext, useState } from "react";
import FirebaseContext from "../../contexts/FirebaseContext"; 
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';


type UserDoc = {
  data: () => {
    photoURL: string | null,
    displayName: string | null,
    uid: string,
    email: string | null,
    phoneNumber: string| null,
  }
  exists: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      margin: "2px 5px",
    },
  }),
);


const Message = (props: any) => {
  const classes = useStyles();

  const {firestore} = useContext(FirebaseContext);
  const { text, uid} = props.message;
  const { messageClass } = props;
  const [photoURL, setphotoURL]: [string, any]  = useState("");
  const [loading, setLoading]: [boolean, any] = useState(true);

  const senderAccountRef = firestore.collection('accounts').doc(uid);
  senderAccountRef.get().then((doc: UserDoc) => {
    if (doc.exists) {
        const url = doc.data().photoURL;
        setphotoURL(url);
        setLoading(false);
    } 
  });

  return(
    <div className={`message ${messageClass}`}>
      {loading
      ? <Skeleton><Avatar variant={"rounded"} className={classes.avatar}/></Skeleton>
      : <Avatar alt="Sender picture" src={photoURL} className={classes.avatar} />
      }
      <p>{text}</p>
    </div>
  )
}

export default Message;