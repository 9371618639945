import React, {useContext} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import {FirebaseContext} from '../../contexts/FirebaseContext';


function Copyright() {
    return (
    <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://raguirre.dev/">
        raguirre.dev
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const {firebase} = useContext(FirebaseContext);
    const {auth} = useContext(FirebaseContext);

    const classes = useStyles();

    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider).then(function(result: any) {
            // The signed-in user info.
            const user = result.user;
            const uid = user.uid;

            const account = {
                email: user.email, // The email of the user.
                displayName: user.displayName, // The display name of the user.
                photoURL: user.photoURL,
                phoneNumber: user.phoneNumber
            }

            firebase.firestore().collection('accounts').doc(uid).set(account); 
        });
    }

    //const signInWithFacebook = () => {
    //    const provider = new firebase.auth.FacebookAuthProvider();
    //    auth.signInWithPopup(provider);
    //}

    return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
        <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
        </Avatar>
        <br />
        <Button color="primary" variant="contained" onClick={signInWithGoogle}>
            Sign in with Google
        </Button>
        {/*<br />
        <Button color="primary" variant="contained" onClick={signInWithFacebook}>
            Sign in with Facebook
        </Button>
        */}
        </div>
        <Box mt={8}>
        <Copyright />
        </Box>
    </Container>
    );
}