import { useContext, useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import Messages from './Messages'
import Header from './Header';
import SignIn from './SignIn';
import './App.css';
import {FirebaseContext} from '../contexts/FirebaseContext';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

export default function App() {
  const {firebase} = useContext(FirebaseContext);
  const {auth} = useContext(FirebaseContext);
  const {firestore} = useContext(FirebaseContext);
  const messagesRef = firestore.collection('messages');  

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  const sendMessage = async (message: string) => {
    if (auth && auth.currentUser){
      const uid = auth.currentUser.uid;
      
      return await messagesRef.add({
        text: message,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid
      });
    }
  }
  
  function signOut () {
    auth.currentUser && auth.signOut();
  }

  const [user] = useAuthState(auth);
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline/>
      <div className="App">
        <Header signOut={signOut}/>
        <section>
          {user? <Messages sendMessage={sendMessage}/> : <SignIn />}
        </section>
      </div>
    </ThemeProvider>
  );
}