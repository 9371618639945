import {createContext} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD6tkGWbh3w6eqDQ48S5XWPqCuyZiRJk1E",
    authDomain: "raguirre-1e03f.firebaseapp.com",
    projectId: "raguirre-1e03f",
    storageBucket: "raguirre-1e03f.appspot.com",
    messagingSenderId: "678853900980",
    appId: "1:678853900980:web:f4de9d65b47c828cb3b5e1",
    measurementId: "G-3LV207HH15"
}

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
}

export interface IFirebaseContext {
    firebase: any,
    auth: firebase.auth.Auth,
    firestore: firebase.firestore.Firestore
}

export const FirebaseContext = createContext({} as any);

export const FirebaseProvider = ({ children }: any) => {
    return (
        <FirebaseContext.Provider value={{ firebase: firebase, auth: firebase.auth(), firestore: firebase.firestore()} as IFirebaseContext}>
            {children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseContext;