import React, {useContext} from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useRef } from 'react';
import Paper from '@material-ui/core/Paper';

import ChatMessage from '../Message/index';
import InputBar from '../InputBar/index';
import './styles.css';
import {FirebaseContext} from '../../contexts/FirebaseContext';

const Messages = ({sendMessage}: {sendMessage:any}) => {

  const {auth} = useContext(FirebaseContext);
  const {firestore} = useContext(FirebaseContext);

  const dummy = useRef<HTMLDivElement>(null);
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt', 'desc').limit(45);
  const [messages] = useCollectionData(query, {idField: 'id'});

  const onSubmitMessage = async (message: any) => {
    sendMessage(message);
    scrollToLatest();
  }

  const scrollToLatest = () => {
    if (dummy && dummy.current) {
      dummy.current.scrollIntoView({ behavior: 'smooth'});
    }
  }

  return (
    <>
      <Paper> 
        <main className="feed">
          
            {
              messages && messages.reverse().map((msg: any) => 
                <ChatMessage 
                  key={msg.id} 
                  message={msg} 
                  messageClass={auth && auth.currentUser && msg.uid === auth.currentUser.uid ? 'sent' : 'received'} 
                />)
            }
            <span ref={dummy}></span>
        </main>
      </Paper>
      <InputBar submitMessage={onSubmitMessage} />

    </>
  )
}

export default Messages;