import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';

import { useState } from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            position: 'fixed',
            bottom: '0',
            maxWidth: '728px'
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
    }),
);

export default function InputBar({submitMessage}: {submitMessage: any}) {
    const classes = useStyles();

    const [formValue, setFormValue] = useState('');

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        formValue.trim() && submitMessage(formValue) && setFormValue('');
    }

    const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setFormValue(e.target.value)
    }

    return (
        <Paper component="form" className={classes.root} onSubmit={handleSubmit}>

            <InputBase
                className={classes.input}
                placeholder="Message..."
                inputProps={{ 'aria-label': 'Message' }}
                onChange={handleChange}
                value={formValue}
            />

            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SendIcon />
            </IconButton>

        </Paper>
    );
}